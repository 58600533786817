import {
  Button,
  Box,
  Container,
  CssBaseline,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Select, MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useSendData from "Components/Axios/SendData";
import { AllowToNumberOnly } from "Components/AllowToNumberOnly/AllowToNumberOnly";
import CustomTextField from "Components/CustomTextField/CustomTextField";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export interface Types {
  id?: any;
  formik: any;
  categories: any;
  isSubmitting: boolean;
  handleClose: () => void;
  formTitle: string;
  saveButtonLabel: string;
  isEdit?: any;
  setImages?: any;
  handleImagesChange?: any;
  images?: File[];
  addOns?: any[];
  // selectedAddOn?: any | null;
  selectedAddOn: string[];
  // setSelectedAddOn?: (value: string | null) => void;
  setSelectedAddOn: (value: string[]) => void;
  selectedAddOnDetails?: any;
  setSelectedAddOnDetails?: (value: any) => void;
  onUpdateSuccess?: any;
  selectedCategory?: any;
}

const FormFields: React.FC<Types> = ({
  formik,
  categories,
  isSubmitting,
  handleClose,
  formTitle,
  saveButtonLabel,
  isEdit,
  images,
  addOns,
  selectedAddOn,
  setSelectedAddOn,
  selectedAddOnDetails,
  setSelectedAddOnDetails,
  setImages,
  handleImagesChange,
  onUpdateSuccess,
  selectedCategory,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { sendData, error, isLoading: sendLoading } = useSendData();

  const handleNumberChange = (fieldName: string, decimal: boolean = false) =>
    AllowToNumberOnly(
      (name: string, value: any) => {
        formik.setFieldValue(name, value);
      },
      fieldName,
      decimal
    );

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      formik.setFieldValue("image", file);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleRemoveOptionAttribute = async (optionId: any) => {
    if (!selectedAddOnDetails) {
      console.error("selectedAddOnDetails is null, cannot proceed.");
      return;
    }

    console.log("selectedAddOnDetails:", selectedAddOnDetails);

    const currentAddOnDetails = formik.values.copied_add_ons_attributes.find(
      (addOn: any) => addOn.id === selectedAddOn
    );

    if (!currentAddOnDetails) {
      console.error("No matching add-on details found.");
      return;
    }

    const newOptions = currentAddOnDetails.options_attributes.map(
      (option: any) => {
        if (option.id === optionId) {
          return { ...option, _destroy: "1" };
        }
        return option;
      }
    );

    const updatedAddOns = formik.values.copied_add_ons_attributes.map(
      (addOn: any) => {
        if (addOn.id === selectedAddOn) {
          return { ...addOn, options_attributes: newOptions };
        }
        return addOn;
      }
    );

    formik.setFieldValue("copied_add_ons_attributes", updatedAddOns);

    const formattedData = {
      food_item: {
        copied_add_ons_attributes: updatedAddOns,
      },
    };

    await sendData({
      endpoint: `food_items/${selectedCategory.id}`,
      data: formattedData,
      isJson: true,
      method: "patch",
    })
      .then((response) => {
        console.log("Option removed successfully:", response);
        // onUpdateSuccess(response);
        if (onUpdateSuccess) {
          onUpdateSuccess(response);
        }
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error removing option:", error);
      });
  };

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{
        bgcolor: (theme) => theme.palette.custom?.dialogBgColor,
        color: (theme) => theme.palette.custom?.dialogColor,
      }}
    >
      <CssBaseline />
      <DialogContent>
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">{formTitle}</Typography>
            <IconButton
              edge="end"
              color="inherit"
              // onClick={handleCloseAddDialog}
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          component="form"
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            <>
              <CustomTextField
                name="name"
                label="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <CustomTextField
                name="price"
                label="Price"
                // type="number"
                value={formik.values.price}
                // onChange={formik.handleChange}
                onChange={handleNumberChange("price", true)}
                error={formik.touched.price && Boolean(formik.errors.price)}
                helperText={formik.touched.price && formik.errors.price}
              />
              <CustomTextField
                name="description"
                label="Description"
                multiline
                rows={4}
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />

              <FormControl fullWidth margin="normal" sx={{ flex: "1 0 45%" }}>
                <input
                  accept="image/*"
                  // id="image-uploader"
                  id="multiple-image-uploader"
                  type="file"
                  // multiple={multiple}
                  onChange={handleImageChange}
                  // multiple
                  style={{ display: "none" }}
                />
                <label htmlFor="multiple-image-uploader">
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Image
                  </Button>
                </label>
              </FormControl>
            </>

            <>
              <FormControl fullWidth margin="normal">
                <InputLabel id="supplier-label">Category</InputLabel>
                <Select
                  // sx={{ flex: "1 0 45%" }}
                  label="Category"
                  value={formik.values.category_id}
                  onChange={(event) =>
                    formik.setFieldValue("category_id", event.target.value)
                  }
                >
                  {categories.map((category: any) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>

            {isEdit === true ? (
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  mt: 3,
                  mb: 2,
                  bgcolor: (theme) => theme.palette.custom?.buttonBgColor,
                  color: (theme) => theme.palette.custom?.buttonColor,
                }}
                // onClick={handleSaveChanges}
                disabled={formik.isSubmitting || sendLoading}
              >
                {saveButtonLabel}
              </Button>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  bgcolor: (theme) => theme.palette.custom?.buttonBgColor,
                  color: (theme) => theme.palette.custom?.buttonColor,
                }}
                disabled={isSubmitting}
              >
                {saveButtonLabel}
              </Button>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Container>
  );
};

export default FormFields;
