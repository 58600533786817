import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
  Box,
} from "@mui/material";

import axios from "axios";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { baseUrl } from "utils/baseUrl";

interface ConfirmDialogProps {
  itemName: string;
  endpoint: string;
  open: boolean;
  onClose: () => void;
  onDeleted: any;
}

const ConfirmDelete: React.FC<ConfirmDialogProps> = ({
  itemName,
  endpoint,
  open,
  onClose,
  onDeleted,
}) => {
  const handleConfirmDelete = async () => {
    const token = localStorage.getItem("userToken");
    try {
      const response = await axios.delete(`${baseUrl}/${endpoint}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // toast.success("Supply deleted successfully");
      toast.success(response.data.message || "Deleted successfully");
      onDeleted(); // استدعاء الدالة بعد الحذف الناجح
    } catch (error) {
      // toast.error("Failed to delete supply");
      if (axios.isAxiosError(error) && error.response) {
        // استخدم الرسالة من جسم الرد إذا كان هناك خطأ من الخادم
        toast.error(error.response.data.message || "Failed to delete ");
      } else {
        // رسالة خطأ عامة إذا لم يكن الخطأ من الخادم
        toast.error("Failed to delete");
      }
    } finally {
      onClose();
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <Box
          sx={{
            bgcolor: (theme) => theme.palette.custom?.dialogBgColor,
            color: (theme) => theme.palette.custom?.dialogColor,
          }}
        >
          <DialogTitle>
            Confirm Delete
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete the ' {itemName} ' ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="info">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="error">
              Delete
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default ConfirmDelete;
