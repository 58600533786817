import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FetchData from "Components/Axios/FetchData";

type SupplyDetailsProps = {
  handleClose: () => void;
  open: boolean;
  purchaseId: any;
};

type AddOnOption = {
  id: number;
  name: string;
  is_per_unit: boolean;
  price: string;
  add_on_type: string;
  add_on_id: number;
  created_at: string;
  updated_at: string;
};

type AddOnDetails = {
  id: number;
  name: string;
  description: string;
  is_single_select: boolean;
  created_at: string;
  updated_at: string;
  options: AddOnOption[];
};

const AddOnsDetails: React.FC<SupplyDetailsProps> = ({
  open,
  handleClose,
  purchaseId,
}) => {
  
  const [addOnDetails, setAddOnDetails] = useState<AddOnDetails | null>(null);

  const {
    data: fetchedData,
    error: fetchError,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading,
  } = FetchData(`add_ons/${purchaseId}`);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [supplies, setSupplies] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (fetchedData && !fetchError) {
      setAddOnDetails(fetchedData); // Assuming the fetchedData is of type AddOnDetails
    }
  }, [fetchedData, fetchError, purchaseId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (fetchError || !addOnDetails) {
    return <div>Error: {fetchError || "Add-On details not found."}</div>;
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogContent>
        <IconButton onClick={handleClose} style={{ float: "right" }}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" gutterBottom textAlign={"center"}>
          Add-Ons Details
        </Typography>
        <Typography variant="subtitle1">
          <strong>Name:</strong> {addOnDetails.name}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Description:</strong> {addOnDetails.description}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Single Select:</strong>{" "}
          {addOnDetails.is_single_select ? "Yes" : "No"}
        </Typography>

        <Typography variant="h6">Options:</Typography>
        <List dense>
          {addOnDetails.options.map((option) => (
            <ListItem key={option.id}>
              <ListItemText
                primary={option.name}
                secondary={`Per Unit: ${
                  option.is_per_unit ? "Yes" : "No"
                }, Price: ${option.price}`}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};
export default AddOnsDetails;
