import React, { useCallback, useEffect, useState } from "react";
import FormFields from "../FormFields";
import ErrorDisplay from "Components/ErrorAndLoading/ErrorDisplay";
import FetchData from "Components/Axios/FetchData";
import useSendData from "Components/Axios/SendData";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import Loading from "Components/ErrorAndLoading/Loading";
import { baseUrl } from "utils/baseUrl";
import axios from "axios";

type Types = {
  handleClose: () => void;
  onUpdateSuccess: any;
  selectedCategory: any;
};

export default function UpdateFoodItem({
  handleClose,
  onUpdateSuccess,
  selectedCategory,
}: Types) {
  const { sendData, error: sendError, isLoading: sendLoading } = useSendData();
  const {
    data: categoriesData,
    error: fetchError,
    isLoading,
  } = FetchData("categories");

  const {
    data: addOnsData,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading: isLoadingAddOns,
    error: fetchAddOnsError,
  } = FetchData("add_ons");

  useEffect(() => {
    if (addOnsData && Array.isArray(addOnsData)) {
      setAddOns(addOnsData);
    } else if (fetchAddOnsError) {
      toast.error("Error fetching add ons.");
    }
  }, [addOnsData, fetchAddOnsError]);

  const [categories, setCategories] = useState([]);
  const [addOns, setAddOns] = useState([]);
  const [selectedAddOn, setSelectedAddOn] = useState(null);
  // const [selectedAddOnDetails, setSelectedAddOnDetails] = useState(null);
  const [images, setImages] = useState<File[]>(selectedCategory.images || []);
  // const [selectedCategories, setSelectedCategories] = useState([]);
  const [categoryData, setCategoryData] = useState(null);
  const [selectedAddOnDetails, setSelectedAddOnDetails] = useState(
    addOnsData && addOnsData.length > 0 ? addOnsData[0] : null
  );

  useEffect(() => {
    if (categoriesData && Array.isArray(categoriesData)) {
      setCategories(categoriesData);
    } else if (categoriesData) {
      toast.error("Error fetching categories.");
    }
  }, [categoriesData]);

  useEffect(() => {
    async function fetchData() {
      try {
        const token = localStorage.getItem("userToken");
        const response = await axios.get(
          `${baseUrl}/food_items/${selectedCategory.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const itemData = response.data;

        // if (!categoryData || categoryData.id !== itemData.id) {
        if (JSON.stringify(categoryData) !== JSON.stringify(itemData)) {
          const newCategoryData = {
            image: itemData.image || null,
            name: itemData.name,
            description: itemData.description,
            price: itemData.price,
            category_id: itemData.category_id,
            copied_add_ons_attributes: itemData.copied_add_ons.map(
              (addOn: any) => ({
                name: addOn.name,
                id: addOn.id,
                description: addOn.description || "",
                is_single_select: addOn.is_single_select,
                options_attributes: addOn.options.map((option: any) => ({
                  id: option.id,
                  name: option.name,
                  is_per_unit: option.is_per_unit,
                  price: option.price,
                })),
              })
            ),
          };

          setCategoryData(newCategoryData);
          formik.setValues(newCategoryData);
        }

        if (itemData.copied_add_ons?.length > 0 && !selectedAddOn) {
          setSelectedAddOn(itemData.copied_add_ons[0].id);
          setSelectedAddOnDetails(itemData.copied_add_ons[0]);
        }
      } catch (error) {
        toast.error("Error fetching category details");
      }
    }

    if (selectedCategory?.id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory?.id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: categoryData || {
      // id: "",
      id: selectedCategory?.id || "",
      image: null,
      name: "",
      description: "",
      price: "",
      category_id: "",
      copied_add_ons_attributes: [],

      // copied_add_ons_attributes: [
      //   {
      //     id: "",
      //     name: "",
      //     description: "",
      //     is_single_select: false,
      //     options_attributes: [
      //       {
      //         id: "",
      //         name: "",
      //         is_per_unit: false,
      //         price: "",
      //       },
      //     ],
      //   },
      // ],
    },
    // validationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      if (values.image) {
        formData.append("food_item[image]", values.image);
      }
      // formData.append("image", values.image);
      formData.append("food_item[name]", values.name);
      formData.append("food_item[description]", values.description);
      formData.append("food_item[price]", values.price.toString());
      formData.append("food_item[category_id]", values.category_id);

      // values.copied_add_ons_attributes.forEach((addOn: any, index: any) => {
      //   formData.append(
      //     `food_item[copied_add_ons_attributes][${index}][id]`,
      //     addOn.id
      //   );

      //   formData.append(
      //     `food_item[copied_add_ons_attributes][${index}][is_single_select]`,
      //     addOn.is_single_select
      //   );
      //   formData.append(
      //     `food_item[copied_add_ons_attributes][${index}][name]`,
      //     addOn.name
      //   );

      //   addOn.options_attributes.forEach((option: any, optionIndex: any) => {
      //     formData.append(
      //       `food_item[copied_add_ons_attributes][${index}][options_attributes][${optionIndex}][id]`,
      //       option.id
      //     );
      //     formData.append(
      //       `food_item[copied_add_ons_attributes][${index}][options_attributes][${optionIndex}][name]`,
      //       option.name
      //     );
      //     formData.append(
      //       `food_item[copied_add_ons_attributes][${index}][options_attributes][${optionIndex}][is_per_unit]`,
      //       option.is_per_unit
      //     );
      //     formData.append(
      //       `food_item[copied_add_ons_attributes][${index}][options_attributes][${optionIndex}][price]`,
      //       option.price
      //     );
      //   });

      //   // }
      // });

      const submittedData = await sendData({
        endpoint: `food_items/${selectedCategory.id}`,
        data: formData,
        // isJson: true,
        method: "patch",
      });
      if (submittedData) {
        onUpdateSuccess(submittedData);
        handleClose();
      }
    },
  });

  const handleImagesChange = useCallback(
    (newImages: File[]) => {
      setImages(newImages);
      formik.setFieldValue("images", newImages);
    },
    [formik]
  );

  const errorToDisplay = fetchError || sendError;
  return (
    <>
      <>
        <ErrorDisplay error={errorToDisplay} />
        <Loading isLoading={isLoading || sendLoading} />
      </>
      <FormFields
        formik={formik}
        handleClose={handleClose}
        isSubmitting={formik.isSubmitting}
        formTitle={"Update Food Item"}
        saveButtonLabel={"Update Food Item"}
        categories={categories}
        addOns={addOns}
        selectedAddOn={selectedAddOn}
        selectedCategory={selectedCategory}
        setSelectedAddOn={setSelectedAddOn}
        selectedAddOnDetails={selectedAddOnDetails}
        setSelectedAddOnDetails={setSelectedAddOnDetails}
        setImages={setImages}
        handleImagesChange={handleImagesChange}
        images={images}
        isEdit={true}
      />
    </>
  );
}
