import { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { baseUrl } from 'utils/baseUrl';

export default function FetchData(endpoint:any) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem('userToken');
      try {
        const response = await axios.get(`${baseUrl}/${endpoint}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data);
        // console.log('==================message ==================');
        // console.log(response.data.message);
        // console.log('====================================');
      } catch (error) {
        let errorMessage = 'An error occurred while fetching data.';
        if (axios.isAxiosError(error) && error.response) {
          errorMessage = error.response.data.message || error.message;
        } else if (error instanceof Error) {
          errorMessage = error.message;
        }
        setError(errorMessage);
        toast.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [endpoint]);

  return { data, error, isLoading };
}
