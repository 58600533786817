import * as Yup from "yup";

export default function validationSchema() {
  return Yup.object({
    name: Yup.string().required("name is required"),
    // description: Yup.string()
    //   .min(5)
    //   .max(300)
    //   .required("description is required"),
    // price: Yup.number().min(1).required("Price is required"),
  });
}
