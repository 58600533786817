import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Grid,
  IconButton,
  Dialog,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { baseUrl } from "utils/baseUrl";
import ConfirmDelete from "Components/ConfirmDelete/ConfirmDelete";
import CreateAddOns from "./CreateAddOns/CreateAddOns";
import UpdateAddOns from "./UpdateAddOns/UpdateAddOns";
import AddOnsDetails from "./AddOnsDetails/AddOnsDetails";
import { useParams } from "react-router-dom";

interface Category {
  id: string;
  name: string;
  description: string;
  price: string;
}

interface AddOnOption {
  id: number;
  name: string;
  is_per_unit: boolean;
  price: string;
  add_on_type: string;
  add_on_id: number;
  created_at: string;
  updated_at: string;
}

interface AddOn {
  id: number;
  name: string;
  description: string | null;
  is_single_select: boolean;
  food_item_id: number;
  created_at: string;
  updated_at: string;
  options: AddOnOption[];
}

interface FoodItem {
  id: number;
  name: string;
  description: string;
  price: string;
  category_id: number;
  created_at: string;
  updated_at: string;
  discarded_at: string | null;
  copied_add_ons: AddOn[];
  image_url: string;
}

export default function AddOns() {
  const [foodItem, setFoodItem] = useState<FoodItem | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);
  const [searchText, setSearchText] = useState("");
  const [openAddDialog, setOpenAddDialog] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<AddOn | null>(null);
  const [viewDetailsOpen, setViewDetailsOpen] = useState(false);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        let token = localStorage.getItem("userToken");

        const response = await axios.get(`${baseUrl}/food_items/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // setCategories(response.data);
        // const { copied_add_ons = [] } = response.data || {};
        // setCategories(copied_add_ons);
        setFoodItem(response.data);
      } catch (error) {
        toast.error("Error fetching categories");
      }
    };

    fetchCategories();
  }, [id]);

  const handleOpenAddDialog = () => setOpenAddDialog(true);
  const handleCloseAddDialog = () => setOpenAddDialog(false);

  const handleOpenEditDialog = (category: AddOn) => {
    setSelectedCategory(category);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => setOpenEditDialog(false);

  const handleOpenDeleteDialog = (category: AddOn) => {
    setSelectedCategory(category);
    setOpenDeleteDialog(true);
  };

  const onUpdateSuccess = (updatedCategory: any) => {
    // First, check if updatedCategory is not undefined
    if (!updatedCategory) {
      console.error("Updated category data is undefined");
      return;
    }

    // Proceed with your logic if updatedCategory is defined
    // setCategories((currentCategories) =>
    //   currentCategories.map((category) =>
    //     category.id === updatedCategory.id ? updatedCategory : category
    //   )
    // );
    setFoodItem((currentFoodItem) =>
      currentFoodItem
        ? {
            ...currentFoodItem,
            copied_add_ons: currentFoodItem.copied_add_ons.map((category) =>
              category.id === updatedCategory.id ? updatedCategory : category
            ),
          }
        : null
    );
    setOpenEditDialog(false);
  };

  const handleOpenViewDetails = (supplyId: number) => {
    // const supply = categories.find((s: any) => s.id === supplyId);
    const supply = foodItem?.copied_add_ons.find((s) => s.id === supplyId);
    if (supply) {
      setSelectedCategory(supply);
      setViewDetailsOpen(true);
    }
  };

  const handleCloseViewDetails = () => {
    setViewDetailsOpen(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleAddCategorySuccess0 = (newCategory: any) => {
    setCategories([...categories, newCategory]);
  };

  const handleAddCategorySuccess = (newCategory: any) => {
    setFoodItem((currentFoodItem) =>
      currentFoodItem
        ? {
            ...currentFoodItem,
            copied_add_ons: [...currentFoodItem.copied_add_ons, newCategory],
          }
        : null
    );
  };

  return (
    <>
      <Box sx={{ p: 2 }}>
        {foodItem && (
          <>
            <Box sx={{ mb: 4, display: "flex", alignItems: "center", gap: 3 }}>
              {foodItem.image_url && (
                <Box
                  component="img"
                  src={foodItem.image_url}
                  alt={foodItem.name}
                  sx={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "8px",
                    objectFit: "cover",
                    boxShadow: 3,
                  }}
                />
              )}
              <Box>
                <Typography variant="h4" gutterBottom>
                  {foodItem.name}
                </Typography>
                <Typography variant="body1">{foodItem.description}</Typography>
                <Typography variant="body1">Price: {foodItem.price}</Typography>
              </Box>
            </Box>

            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <TextField
                label="Search"
                variant="outlined"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />

              <Button
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}
                onClick={handleOpenAddDialog}
                sx={{
                  bgcolor: (theme) => theme.palette.custom?.buttonBgColor,
                  color: (theme) => theme.palette.custom?.buttonColor,
                }}
              >
                Add New Add_Ons
              </Button>
            </Box>

            <Grid
              container
              spacing={2}
              sx={{
                minWidth: "63vw",
                "& > .MuiGrid-item": {
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  minWidth: "300px",
                },
              }}
            >
              {foodItem.copied_add_ons
                .filter((category) =>
                  category.name.toLowerCase().includes(searchText.toLowerCase())
                )
                .map((category) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={category.id}>
                    <Card
                      sx={{
                        bgcolor: (theme) => theme.palette.custom?.card,
                      }}
                    >
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {category.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Single Select{" "}
                          {category.is_single_select ? "Yes" : "No"}
                        </Typography>
                        {/* <Typography variant="body2" color="text.secondary">
                          {category.description}
                        </Typography> */}
                        {/* <Typography variant='body2' color='text.secondary'>
                          {category.options.length} Options
                        </Typography> */}
                      </CardContent>
                      <Grid container justifyContent="flex-end">
                        <IconButton
                          onClick={() => handleOpenViewDetails(category.id)}
                        >
                          <VisibilityIcon />
                        </IconButton>

                        <IconButton
                          onClick={() => handleOpenEditDialog(category)}
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          onClick={() => handleOpenDeleteDialog(category)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </>
        )}
      </Box>

      <Dialog
        open={openAddDialog}
        onClose={handleCloseAddDialog}
        maxWidth="sm"
        fullWidth
      >
        <Box
          sx={{
            bgcolor: (theme) => theme.palette.custom?.dialogBgColor,
            color: (theme) => theme.palette.custom?.dialogColor,
          }}
        >
          <CreateAddOns
            handleClose={handleCloseAddDialog}
            onAddSupply={handleAddCategorySuccess}
            id={id}
          />
        </Box>
      </Dialog>

      {openDeleteDialog && selectedCategory && (
        <ConfirmDelete
          itemName={selectedCategory.name}
          endpoint={`copied_add_ons/${selectedCategory.id}`}
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          // onDeleted={() => {
          //   setCategories(
          //     categories.filter((supply) => supply.id !== selectedCategory.id)
          //   );
          //   setOpenDeleteDialog(false);
          // }}
          onDeleted={() => {
            setFoodItem((currentFoodItem) =>
              currentFoodItem
                ? {
                    ...currentFoodItem,
                    copied_add_ons: currentFoodItem.copied_add_ons.filter(
                      (supply) => supply.id !== selectedCategory.id
                    ),
                  }
                : null
            );
            setOpenDeleteDialog(false);
          }}
        />
      )}

      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <Box
          sx={{
            bgcolor: (theme) => theme.palette.custom?.dialogBgColor,
            color: (theme) => theme.palette.custom?.dialogColor,
          }}
        >
          {selectedCategory && (
            <UpdateAddOns
              selectedCategory={selectedCategory}
              handleClose={handleCloseEditDialog}
              onUpdateSuccess={onUpdateSuccess}
            />
          )}
        </Box>
      </Dialog>

      {selectedCategory && (
        <AddOnsDetails
          purchaseId={selectedCategory.id}
          open={viewDetailsOpen}
          handleClose={handleCloseViewDetails}
        />
      )}
    </>
  );
}
