// CategoryDetails.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { CardContent, Grid, Typography } from "@mui/material";
import { Card } from "@mui/material";
import { baseUrl } from "utils/baseUrl";

interface Item {
  id: string;
  name?: string;
  description?: string;
  price?: number;
}

interface CategoryDetails {
  id: string;
  name: string;
  description: string;
  food_items: Item[];
}

const initialCategoryDetails: CategoryDetails = {
  id: "",
  name: "",
  description: "",
  food_items: [],
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export default function CategoryDetails() {
  const { id } = useParams<{ id: string }>();
  const [categoryDetails, setCategoryDetails] =
    useState<CategoryDetails | null>(initialCategoryDetails);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  // Fetch category details
  useEffect(() => {
    setLoading(true);
    let token = localStorage.getItem("userToken");
    axios
      .get(`${baseUrl}/categories/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCategoryDetails(response.data);
      })
      .catch((error: any) => {
        setError(error.response?.data?.message || "An error occurred");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg={3}
        key={categoryDetails.id}
        style={{
          maxWidth: "300px",
          textAlign: "center",
          // height: "100px",
          // margin: "10px",
        }}
      >
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {categoryDetails.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {categoryDetails.description}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <br />

      <>
        <h1>Food Items</h1>
        <Grid
          container
          spacing={2}
          sx={{
            minWidth: "63vw",
            "& > .MuiGrid-item": {
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              minWidth: "300px",
            },
          }}
        >
          {categoryDetails?.food_items?.length > 0 ? (
            categoryDetails.food_items.map((item) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={item.id}
                style={{ maxWidth: "300px" }}
              >
                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {item.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item.description}
                    </Typography>
                    <Typography variant="body1">
                      Price: ${item.price}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <p>No food items available.</p>
          )}
        </Grid>
      </>
    </>
  );
}
