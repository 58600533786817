import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function ProtectedRoutes({ children }) {
  const navigate = useNavigate();
  // const [isMounted, setIsMounted] = useState(true);
  var isMounted = true;

  useEffect(() => {
    let isMounted = true;
    const checkUserStatus = async () => {
      try {
        const token = localStorage.getItem("userToken");
        if (!token) throw new Error("No token found");
        
      } catch (error) {
        if (isMounted) {
          toast.error(
            error.response?.data?.message ||
              error.message ||
              "Failed to validate status, logging out."
          );
          localStorage.removeItem("userToken");
          // localStorage.removeItem("user");
          navigate("/login");
        }
      }
    };

    checkUserStatus();
    return () => {
      isMounted = false;
    };
  }, [navigate, isMounted]);

  return children;
}

// ...

export function RedirectIfLoggedIn({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    const checkUserStatusAndRedirect = async () => {
      const token = localStorage.getItem("userToken");
      if (token) {
        navigate("/dashboard");
      } else {
        toast.error("Failed to validate status, redirecting to login.");
        localStorage.removeItem("userToken");
        navigate("/login");
      }
    };

    checkUserStatusAndRedirect();
  }, [navigate]);

  return children;
}
