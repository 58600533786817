// import React, { useEffect } from "react";
// import { RouterProvider } from "react-router-dom";
// import AppRouter from "./routes";
// import { Toaster } from "react-hot-toast";
import { ToastContainer } from "react-toastify";
import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./App.scss";
import MyRoutes from "./MyRoutes";

function App() {

  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("loginTime");
    navigate("/login");
  }, [navigate]);

  useEffect(() => {
    const handleAutoLogout = () => {
      const loginTime = localStorage.getItem("loginTime");
      if (loginTime) {
        const currentTime = Date.now();
        const timePassed = currentTime - parseInt(loginTime);

        if (timePassed > 24 * 3600 * 1000) { 
          handleLogout();
        }
      }
    };

    handleAutoLogout();
    const interval = setInterval(handleAutoLogout, 3600 * 1000); 
    return () => clearInterval(interval);
  }, [handleLogout]);

  return (
    <div className="App">
      {/* <Toaster /> */}
      <ToastContainer
        theme="colored"
        // position="top-center"
      />
      {/* <RouterProvider router={router} /> */}
      <MyRoutes />
    </div>
  );
}

export default App;
