import {
  Button,
  Box,
  Container,
  CssBaseline,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  FormLabel,
  FormGroup,
  Switch,
} from "@mui/material";
// import Dropdown from "Components/Dropdown/Dropdown";
import CloseIcon from "@mui/icons-material/Close";
import useSendData from "Components/Axios/SendData";
import CustomTextField from "Components/CustomTextField/CustomTextField";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { v4 as uuidv4 } from "uuid";

export interface Types {
  formik: any;
  isSubmitting: boolean;
  handleClose: () => void;
  formTitle: string;
  saveButtonLabel: string;
  isEdit?: any;
  selectedCategory?: any;
  onUpdateSuccess?: any;
}

type OptionType = {
  id?: string;
  name: string;
  is_per_unit: boolean;
  price: number;
  _destroy?: "1" | undefined;
};

const FormFields: React.FC<Types> = ({
  formik,
  isSubmitting,
  handleClose,
  formTitle,
  saveButtonLabel,
  isEdit,
  selectedCategory,
  onUpdateSuccess,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { sendData, error, isLoading: sendLoading } = useSendData();
  // const [isImagesSelected, setIsImagesSelected] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const handleNumberChange = (fieldName: string, decimal: boolean = false) =>
  //   AllowToNumberOnly(
  //     (name: string, value: any) => {
  //       formik.setFieldValue(name, value);
  //     },
  //     fieldName,
  //     decimal
  //   );

  const handleNumberChange = (optionId: any, fieldName: any, decimal = false) => {
    return (event: any) => {
      let value = event.target.value;
      const regex = decimal ? /^[0-9]*\.?[0-9]*$/ : /^[0-9]*$/;
  
      if (regex.test(value)) {
        const index = formik.values.options_attributes.findIndex(
          (option: any) => option.id === optionId
        );
        const updatedOptions = [...formik.values.options_attributes];
        if (index !== -1) {
          updatedOptions[index][fieldName] = value;
          formik.setFieldValue("options_attributes", updatedOptions);
        }
      }
    };
  };

  const handleAddOptionAttribute = () => {
    const newOption: OptionType = {
      // id: nanoid(),
      id: uuidv4(),
      name: "",
      is_per_unit: false,
      price: 0,
      // _destroy: undefined,
    };

    const optionsWithoutDestroyed = formik.values.options_attributes.filter(
      (option: any) => option._destroy !== "1"
    );

    formik.setFieldValue("options_attributes", [
      ...optionsWithoutDestroyed,
      newOption,
    ]);
  };
  // Function to handle option attribute change
  const handleOptionAttributeChange = (id: any, field: any, value: any) => {
    const index = formik.values.options_attributes.findIndex(
      (option: any) => option.id === id
    );

    // Proceed only if a valid index is found
    if (index > -1) {
      const newOptions = [...formik.values.options_attributes];
      newOptions[index] = { ...newOptions[index], [field]: value };

      formik.setFieldValue("options_attributes", newOptions);
    }
  };

  const handleRemoveOptionAttribute = (idToRemove: any) => {
    const newOptions = formik.values.options_attributes.map((option: any) => {
      if (option.id === idToRemove) {
        return { ...option, _destroy: "1" };
      }
      return option;
    });

    // formik.setFieldValue("options_attributes", newOptions);
    formik.setFieldValue(
      "options_attributes",
      formik.values.options_attributes.filter(
        (option: any) => option.id !== idToRemove
      )
    );

    if (!selectedCategory) {
      return;
    }

    const formattedData = {
      add_on: {
        options_attributes: newOptions,
      },
    };

    sendData({
      endpoint: `add_ons/${selectedCategory.id}`,
      data: formattedData,
      isJson: true,
      method: "patch",
    })
      .then((response) => {
        // Handle the response
        onUpdateSuccess(response);
      })
      .catch((error) => {
        console.error("Error removing option:", error);
      });
  };

  const renderOptionAttributes = () => {
    if (!Array.isArray(formik.values.options_attributes)) {
      console.error(
        "Expected an array for options_attributes, but received:",
        formik.values.options_attributes
      );
      return null;
    }
    return (
      <>
        {formik.values.options_attributes.map((option: any) => (
          <Box key={option.id} sx={{ marginBottom: 2 }}>
            <CustomTextField
              name="name"
              label="Option Name"
              value={option.name}
              onChange={(e: any) =>
                handleOptionAttributeChange(option.id, "name", e.target.value)
              }
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />

            <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend">Is Per Unit</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={option.is_per_unit}
                      onChange={(e) =>
                        handleOptionAttributeChange(
                          option.id,
                          "is_per_unit",
                          e.target.checked
                        )
                      }
                    />
                  }
                  label={option.is_per_unit ? "Yes" : "No"}
                />
              </FormGroup>
            </FormControl>

            <CustomTextField
              name="price"
              label="Price"
              // type="number"
              value={option.price}
              // onChange={(e: any) =>
              //   handleOptionAttributeChange(option.id, "price", e.target.value)
              // }
              // onChange={handleNumberChange("price", true)}
              onChange={handleNumberChange(option.id, "price", true)}
              error={formik.touched.price && Boolean(formik.errors.price)}
              helperText={formik.touched.price && formik.errors.price}
            />

            <IconButton
              onClick={() => handleRemoveOptionAttribute(option.id)}
              aria-label="Remove"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ))}
      </>
    );
  };

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{
        bgcolor: (theme) => theme.palette.custom?.dialogBgColor,
        color: (theme) => theme.palette.custom?.dialogColor,
      }}
    >
      <CssBaseline />
      <DialogContent>
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">{formTitle}</Typography>
            <IconButton
              edge="end"
              color="inherit"
              // onClick={handleCloseAddDialog}
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          component="form"
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            <>
              <CustomTextField
                name="name"
                label="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />

              <CustomTextField
                name="description"
                label="Description"
                multiline
                rows={4}
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />

              {/* <CustomTextField
                name="price"
                label="Price"
                // type="number"
                value={formik.values.price}
                // onChange={formik.handleChange}
                onChange={handleNumberChange("price", true)}
                error={formik.touched.price && Boolean(formik.errors.price)}
                helperText={formik.touched.price && formik.errors.price}
              /> */}

              <FormControl component="fieldset">
                <FormLabel component="legend">Is Single Select</FormLabel>
                <RadioGroup
                  row
                  name="is_single_select"
                  value={formik.values.is_single_select}
                  onChange={(event) =>
                    formik.setFieldValue(
                      "is_single_select",
                      event.target.value === "true"
                    )
                  }
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </>

            {renderOptionAttributes()}
            <Button onClick={handleAddOptionAttribute}>Add Option</Button>

            <>
              {/* <Select
                label="Category"
                value={formik.values.category_id}
                onChange={(event) =>
                  formik.setFieldValue("category_id", event.target.value)
                }
              >
                {categories.map((category: any) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select> */}
            </>

            {isEdit === true ? (
              <Button
                variant="contained"
                fullWidth
                sx={{
                  mt: 3,
                  mb: 2,
                  bgcolor: (theme) => theme.palette.custom?.buttonBgColor,
                  color: (theme) => theme.palette.custom?.buttonColor,
                }}
                // onClick={handleSaveChanges}
                disabled={formik.isSubmitting || sendLoading}
              >
                {saveButtonLabel}
              </Button>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  bgcolor: (theme) => theme.palette.custom?.buttonBgColor,
                  color: (theme) => theme.palette.custom?.buttonColor,
                }}
                disabled={isSubmitting}
              >
                {saveButtonLabel}
              </Button>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Container>
  );
};

export default FormFields;
