import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FetchData from "Components/Axios/FetchData";

interface FoodItem {
  id: number;
  name: string;
  description: string;
  price: string;
  category_id: number;
  created_at: string;
  updated_at: string;
  copied_add_ons: CopiedAddOn[];
}

interface CopiedAddOn {
  id: number;
  name: string;
  description: string | null;
  is_single_select: boolean;
  options: AddOnOption[];
}

interface AddOnOption {
  id: number;
  name: string;
  is_per_unit: boolean;
  price: string;
}

type FoodItemsDetailsProps = {
  handleClose: () => void;
  open: boolean;
  foodItem: FoodItem;
};

const FoodItemsDetails: React.FC<FoodItemsDetailsProps> = ({
  foodItem,
  open,
  handleClose,
}) => {
  const {
    data: fetchedData,
    error: fetchError,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading,
  } = FetchData(`food_items/${foodItem.id}`);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [supplies, setSupplies] = useState<FoodItem | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string | null>(null);


  useEffect(() => {
    if (fetchedData) {
      setSupplies(fetchedData);
    } else if (fetchError) {
      setError(`Error loading`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedData, fetchError]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  if (!supplies) return <div>Data is not available.</div>;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogContent>
        <Grid container spacing={3} p="15px">
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Food Item Details
            </Typography>
            <Typography variant="subtitle1">
              <strong>Name:</strong> {foodItem.name}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Description:</strong> {foodItem.description}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Price:</strong> ${foodItem.price}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Updated At:</strong>{" "}
              {new Date(supplies.updated_at).toLocaleString()}
            </Typography>

            <Typography variant="subtitle1" mt={2}>
              <strong>Add-Ons:</strong>
            </Typography>
            {supplies.copied_add_ons &&
              supplies.copied_add_ons.map((addOn) => (
                <div key={addOn.id}>
                  <Typography variant="subtitle1">
                    <strong>Add-On Name:</strong> {addOn.name}
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>Single Select:</strong>{" "}
                    {addOn.is_single_select ? "Yes" : "No"}
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>Options:</strong>
                  </Typography>
                  {addOn.options.map((option) => (
                    <Typography key={option.id} variant="subtitle2">
                      - {option.name}: ${option.price} (Per Unit:{" "}
                      {option.is_per_unit ? "Yes" : "No"})
                    </Typography>
                  ))}
                </div>
              ))}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default FoodItemsDetails;
