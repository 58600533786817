import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import { baseUrl } from "utils/baseUrl";

interface DeleteCategoryProps {
  open: boolean;
  onClose: () => void;
  category: any;
  onDeleteSuccess: (deletedCategoryId: string) => void;
}

const DeleteCategory: React.FC<DeleteCategoryProps> = ({
  open,
  onClose,
  category,
  onDeleteSuccess,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    if (!category) return;

    setIsDeleting(true);
    try {
      let token = localStorage.getItem("userToken");

      await axios.delete(`${baseUrl}/categories/${category.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      onDeleteSuccess(category.id);
      toast.success("Category deleted successfully");
    } catch (error) {
      toast.error("Error deleting category");
    } finally {
      setIsDeleting(false);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ "& .MuiDialog-paper": { minWidth: "320px", borderRadius: "8px" } }}
    >
      <Box
        sx={{
          bgcolor: (theme) => theme.palette.custom?.dialogBgColor,
          color: (theme) => theme.palette.custom?.dialogColor,
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Confirm Delete
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContentText sx={{ m: 2 }}>
          Are you sure you want to delete {category?.name}?
        </DialogContentText>
        <DialogActions sx={{ m: 0, p: 2 }}>
          <Button
            onClick={onClose}
            variant="outlined"
            color="info"
            sx={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            variant="contained"
            color="secondary"
            disabled={isDeleting}
            sx={{
              textTransform: "none",
              ml: 2,
              bgcolor: (theme) => theme.palette.custom?.buttonBgColor,
              color: (theme) => theme.palette.custom?.buttonColor,
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DeleteCategory;
