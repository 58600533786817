import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { baseUrl } from "utils/baseUrl";
import * as Yup from "yup";

interface UpdateCategoryProps {
  open: boolean;
  onClose: () => void;
  category: any;
  onUpdateSuccess: any;
}

const UpdateCategorySchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
});

const UpdateCategory: React.FC<UpdateCategoryProps> = ({
  open,
  onClose,
  category,
  onUpdateSuccess,
}) => {
  const formik = useFormik({
    initialValues: {
      name: category?.name || "",
      description: category?.description || "",
    },
    validationSchema: UpdateCategorySchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const payload = {
        name: values.name,
        description: values.description,
      };

      try {
        const token = localStorage.getItem("userToken");

        const response = await axios.put(
          `${baseUrl}/categories/${category?.id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        onUpdateSuccess(response.data);
        toast.success("Category updated successfully");
        onClose();
      } catch (error) {
        toast.error("Error updating category");
      }
    },
  });

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Box
        sx={{
          bgcolor: (theme) => theme.palette.custom?.dialogBgColor,
          color: (theme) => theme.palette.custom?.dialogColor,
        }}
      >
        <DialogTitle>
          Edit Category
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <TextField
              fullWidth
              id="name"
              name="name"
              label="Category Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={
                formik.touched.name && typeof formik.errors.name === "string"
                  ? formik.errors.name
                  : ""
              }
            />
            <TextField
              sx={{
                mt: 3,
                mb: 3,
              }}
              fullWidth
              id="description"
              name="description"
              label="Description"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description &&
                typeof formik.errors.description === "string"
                  ? formik.errors.description
                  : ""
              }
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={formik.isSubmitting}
              sx={{
                bgcolor: (theme) => theme.palette.custom?.buttonBgColor,
                color: (theme) => theme.palette.custom?.buttonColor,
              }}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
};

export default UpdateCategory;
