import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { baseUrl } from "utils/baseUrl";
import {
  Grid,
  Card,
  CardMedia,
  CardActions,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// import ConfirmDelete from "Components/ConfirmDelete/ConfirmDelete";
import useSendData from "Components/Axios/SendData";
import { useFormik } from "formik";
import Loading from "Components/ErrorAndLoading/Loading";

const FoodItemsCarousels = () => {
  const [images, setImages] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setisLoading] = useState(false);

  const {
    sendData,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    error: sendError,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading: sendLoading,
  } = useSendData();

  useEffect(() => {
    const fetchImages = async () => {
      setisLoading(true);

      try {
        let token = localStorage.getItem("userToken");

        const response = await axios.get(`${baseUrl}/carousels/1`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setImages(response.data.images);
      } catch (error) {
        console.error("Error fetching images", error);
      }
      setisLoading(false);
    };

    fetchImages();
  }, []);

  const handleDelete = async (id: any) => {
    try {
      let token = localStorage.getItem("userToken");

      const response = await axios.delete(
        `${baseUrl}/carousels/1/remove_images`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: {
            image_ids: [id], // Sending an array of ids, as expected by your backend.
          },
        }
      );

      // Check if the response is successful before updating the state
      if (response.status === 200) {
        setImages(images.filter((image) => image.id !== id));
      }
    } catch (error) {
      console.error("Error deleting the image", error);
    } finally {
      setOpenDeleteDialog(false);
    }
  };

  const handleAddImage = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("images[]", file);

      try {
        let token = localStorage.getItem("userToken");
        const response = await axios.post(
          `${baseUrl}/carousels/1/add_images`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // If the request is successful, update the state with the new image
        if (response.status === 201) {
          // Assuming 'added_images' is an array of image objects
          setImages((prevImages) => [
            ...prevImages,
            ...response.data.added_images,
          ]);
        } else {
          // If the status code is not 201, handle it here
          console.error("Image upload failed with status: ", response.status);
        }
      } catch (error) {
        // Check for error response details
        console.error("Error adding the image");
      }
    } else {
      console.error("No file selected.");
    }
  };

  const handleFileChange = (event: any) => {
    setFile(event.target.files[0]);
  };

  const handleOpenDeleteDialog = (id: any) => {
    setSelectedImageId(id);
    setOpenDeleteDialog(true);
  };

  interface ConfirmDeleteProps {
    itemName: string;
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
  }

  const ConfirmDelete: React.FC<ConfirmDeleteProps> = ({
    itemName,
    open,
    onClose,
    onConfirm,
  }) => {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure you want to delete  this img ?`}
          {/* ${itemName} */}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      image: null,
    },
    // validationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      if (values.image) {
        formData.append("images[]", values.image);
      }

      const submittedData = await sendData({
        endpoint: "carousels/1/add_images",
        // data: formattedData,
        data: formData,
        // data: {
        //   ...formattedData,
        //   add_ons: selectedAddOnDetails ? [selectedAddOnDetails] : [],
        // },
        // isJson: false,
        method: "post",
      });
      if (submittedData) {
        // onAddSupply(submittedData);
        console.log("Submitted data:", submittedData);
        console.log(...formData);
      }
    },
  });

  // const handleImagesChange = useCallback(
  //   (newImages: File[]) => {
  //     setImages(newImages);
  //     formik.setFieldValue("images", newImages);
  //   },
  //   [formik]
  // );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleImagesChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const newImages = Array.from(event.target.files);
        setImages(newImages); // Assuming you want to store the File objects in your state
        formik.setFieldValue("images", newImages);
      }
    },
    [formik]
  );

  // const handleUpload = () => {
  //   if (file) {
  //     handleAddImage();
  //   } else {
  //     alert("Please select an image first.");
  //   }
  // };

  return (
    <>
      <>
        <Loading isLoading={loading} />
      </>
      <input
        accept="image/*"
        type="file"
        onChange={handleFileChange}
        style={{ display: "none" }}
        id="add-image-file"
      />
      <label htmlFor="add-image-file">
        <Button
          variant="contained"
          component="span"
          // startIcon={<AddPhotoAlternateIcon />}
        >
          Choose Image
        </Button>
      </label>

      <Button
        variant="contained"
        startIcon={<AddCircleOutlineIcon />}
        onClick={handleAddImage}
        sx={{
          bgcolor: (theme) => theme.palette.custom?.buttonBgColor,
          color: (theme) => theme.palette.custom?.buttonColor,
          ml: 2,
        }}
      >
        Add New Image
      </Button>

      {/* <FormControl fullWidth margin="normal" sx={{ flex: "1 0 45%" }}>
        <input
          accept="image/*"
          id="multiple-image-uploader"
          type="file"
          onChange={handleImagesChange}
          // onChange={(event) => {
          //   // When files are selected, set the field in Formik
          //   if (event.currentTarget.files) {
          //     formik.setFieldValue("images", event.currentTarget.files);
          //   }
          // }}
          multiple
          style={{ display: "none" }}
        />
        <label htmlFor="multiple-image-uploader">
          <Button
            variant="contained"
            component="span"
            startIcon={<CloudUploadIcon />}
            onClick={(e) => {
              // e.preventDefault(); // Prevent default button click behavior
              formik.handleSubmit(); // Call Formik's handleSubmit
            }}
            // type="submit"
          >
            Upload Image
          </Button>
        </label>
      </FormControl> */}

      <Grid
        container
        spacing={2}
        sx={{
          minWidth: "73vw",
          "& > .MuiGrid-item": {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            minWidth: "300px",
          },
          mt: 2,
        }}
      >
        {images.map((image, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
            <Box>
              <Card>
                <CardMedia
                  component="img"
                  image={image.url}
                  alt={`Carousel ${image.id}`}
                  sx={{ height: "200px", width: "100%", objectFit: "cover" }}
                />
                <CardActions>
                  <IconButton
                    onClick={() => handleOpenDeleteDialog(image.id)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Box>
          </Grid>
        ))}
      </Grid>
      {openDeleteDialog && selectedImageId && (
        <ConfirmDelete
          itemName={`image ${selectedImageId}`}
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          onConfirm={() => {
            handleDelete(selectedImageId);
            setOpenDeleteDialog(false);
          }}
        />
      )}

      {/* {openDeleteDialog && selectedCategory && (
        <ConfirmDelete
          itemName={selectedCategory.name}
          endpoint={`carousels/1/remove_images/${selectedCategory.id}`}
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          onDeleted={() => {
            setCategories(
              categories.filter((supply) => supply.id !== selectedCategory.id)
            );
            setOpenDeleteDialog(false);
          }}
        />
      )} */}
    </>
  );
};

export default FoodItemsCarousels;
